import { configs } from "@/constants/configs";
import { DefaultLayout } from "@/layouts/default-layout/DefaultLayout";

export default function NotFound() {
  return (
    <DefaultLayout pageTitle={`${configs.defaultPageTitle} - 404`}>
      <p>Sorry, page not found!</p>
    </DefaultLayout>
  );
}
